var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-layout-wrapper"},[_c('div',{staticClass:"custom-login-bg",style:({background: '#335a3f url(' + _vm.loginBgUrl + ') no-repeat 50%',
             backgroundSize: 'cover', backgroundAttachment: 'fixed'})},[_c('div',{staticClass:"custom-login"},[_c('div',{staticClass:"custom-login-right"},[_c('div',{staticClass:"custom-login-form"},[_c('div',{staticClass:"login-border"},[_c('div',{staticClass:"login-logo"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logoUrl,"alt":"logo"}})]),_c('h2',{staticClass:"custom-login-title"},[_vm._v(" "+_vm._s(_vm.appName)+" ")]),_c('div',{staticClass:"login-content"},[_c('a-form',{attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"custom-margin-bottom-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['emp_no', {
                      normalize: this.$lodash.trim,
                      rules: [
                        { required: true, message: '请输入工号' }
                      ]
                    }]),expression:"['emp_no', {\n                      normalize: this.$lodash.trim,\n                      rules: [\n                        { required: true, message: '请输入工号' }\n                      ]\n                    }]"}],attrs:{"placeholder":"工号"},on:{"change":_vm.handleEmpNo}})],1),_c('a-form-item',{staticClass:"custom-margin-bottom-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
                      rules: [
                        { required: true, message: '请输入登录密码' }
                      ]
                    }]),expression:"['password', {\n                      rules: [\n                        { required: true, message: '请输入登录密码' }\n                      ]\n                    }]"}],attrs:{"type":"password","placeholder":"密码"}})],1),_c('a-form-item',{staticClass:"custom-margin-bottom-10"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['funeral_home_id', {
                      rules: [{ required: true, message: '请选择所属殡仪馆' }]
                    }]),expression:"['funeral_home_id', {\n                      rules: [{ required: true, message: '请选择所属殡仪馆' }]\n                    }]"}],attrs:{"show-search":"","filter-option":_vm.filterOption,"option-filter-prop":"children","loading":_vm.loadingFuneralHomeOptions,"placeholder":"所属殡仪馆"},on:{"focus":_vm.fetchFuneralHomeOptions}},_vm._l((_vm.funeralHomeOptions),function(home){return _c('a-select-option',{key:home.id,attrs:{"value":home.id}},[_vm._v(" "+_vm._s(home.name)+" ")])}),1)],1),_c('a-form-item',{staticClass:"custom-margin-bottom-10"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['screen_type', {
                      rules: [{ required: true, message: '请选择类型' }]
                    }]),expression:"['screen_type', {\n                      rules: [{ required: true, message: '请选择类型' }]\n                    }]"}],attrs:{"placeholder":"请选择类型"}},_vm._l((_vm.screenTypeOptions),function(screenType){return _c('a-select-option',{key:screenType.value,attrs:{"value":screenType.value}},[_vm._v(" "+_vm._s(screenType.name)+" ")])}),1)],1),_c('a-form-item',[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":18}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['captcha', {
                          normalize: this.$lodash.trim,
                          rules: [{ required: true, message: '请输入验证码' }]
                        }]),expression:"['captcha', {\n                          normalize: this.$lodash.trim,\n                          rules: [{ required: true, message: '请输入验证码' }]\n                        }]"}],attrs:{"placeholder":"验证码"}})],1),_c('a-col',{attrs:{"span":6}},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 点击刷新验证码 ")]),_c('a-spin',{attrs:{"spinning":_vm.loadingCaptchaImg}},[(_vm.captchaId.length > 0)?_c('img',{staticClass:"captcha-img custom-cursor-pointer",attrs:{"src":_vm.captchaImgUrl,"alt":""},on:{"click":_vm.reloadCaptcha,"load":_vm.captchaImgLoaded}}):_vm._e()])],2)],1)],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.submitting}},[_vm._v(" 立即登录 ")])],1)],1),_c('div',{domProps:{"innerHTML":_vm._s(_vm.tips)}})],1)])])])]),_c('a-row',{staticClass:"footer",attrs:{"type":"flex","justify":"space-around","align":"middle"}},[_c('span',[_vm._v(_vm._s(_vm.copyright))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }